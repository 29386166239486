<template>
    <div>
        <header class="header">
            <h2>STOCK  MADURACIÓN</h2>  
        </header>
        <div class="main-content">
            <aside class="derecha">Izquierda</aside>
            <v-row>
                <v-col cols="3" >
                    <Bar  :chart-options="chartOptions"
                    :chart-data="chartData"/>
                </v-col>
                <v-col cols="3">
                    <Bar  :chart-options="chartOptions"
                    :chart-data="chartData"/>
                </v-col>
                <v-col cols="3">
                    <Bar  :chart-options="chartOptions2"
                    :chart-data="chartData2"/>
                </v-col>
                <v-col cols="3">
                    <Bar  :chart-options="chartOptions4"
                    :chart-data="chartData4"/>
                </v-col>
            </v-row>
            <aside class="izquierda">
                <div >
                    <Bar  :chart-options="chartOptions3"
                    :chart-data="chartData3"/>
                </div>
            </aside>
        </div>
    </div>

</template>

<script>
   
    import _sPalletSend from "@/services/FrozenProduction/FrzLotProductionSelectionPalletSendService.js"

    import { Bar } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);


    export default {
        name: 'BarChart',
        components:{
            Bar
        },
        props:{
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Array,
                default: () => []
            }
        },
        data(){
            return{
                chartData: {
                    labels: [], // Etiquetas para el eje X
                    datasets: [
                    {
                        label: 'PESO POR TIPO DE SELECCION',
                        backgroundColor: '#42A5F5', // Color de las barras
                        data: [] // Datos para las barras
                    }
                    ]
                  
                },
                chartOptions:{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                            label: function(tooltipItem) {
                                return `Peso: ${tooltipItem.raw} TN`;
                                    }
                                }
                            }
                    },
                    scales: {
                        x: {
                            beginAtZero: true
                        },
                        y: {
                            beginAtZero: true
                        }
                    }
                },
                chartData2: {
                    labels: [], // Etiquetas para el eje X
                    datasets: [
                    {
                        label: 'PESO POR ESTADO DEL PROCESO',
                        backgroundColor: '#42A5F5', // Color de las barras
                        data: [] // Datos para las barras
                    }
                    ]
                  
                },
                chartOptions2:{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                            label: function(tooltipItem) {
                                return `Peso: ${tooltipItem.raw} TN`;
                                    }
                                }
                            }
                    },
                    scales: {
                        x: {
                            beginAtZero: true
                        },
                        y: {
                            beginAtZero: true
                        }
                    }
                },
                chartData3: {
                    labels: [], // Etiquetas para el eje X
                    datasets: [
                    {
                        label: 'PESO POR TAMAÑO',
                        backgroundColor: '#42A5F5', // Color de las barras
                        data: [] // Datos para las barras
                    }
                    ]
                  
                },
                chartOptions3:{
                    responsive: true,
                    indexAxis: 'y', 
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                            label: function(tooltipItem) {
                                return `Peso: ${tooltipItem.raw} TN`;
                                    }
                                }
                            }
                    },
                    scales: {
                        x: {
                            beginAtZero: true
                        },
                        y: {
                            beginAtZero: true
                        }
                    }
                },
                chartData4: {
                    labels: [], // Etiquetas para el eje X
                    datasets: [
                    {
                        label: 'PESO POR TAMAÑO',
                        backgroundColor: '#42A5F5', // Color de las barras
                        data: [] // Datos para las barras
                    }
                    ]
                  
                },
                chartOptions4:{
                    responsive: true,
                   
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                            label: function(tooltipItem) {
                                return `Peso: ${tooltipItem.raw} TN`;
                                    }
                                }
                            }
                    },
                    scales: {
                        x: {
                            beginAtZero: true
                        },
                        y: {
                            beginAtZero: true
                        }
                    }
                },
            }
        },
        methods:{
            getTypeSelectionFrozen()
            {
                _sPalletSend.getWeightTypeSelectionFrozen({}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        let data = r.data;
                        
                        if (Array.isArray(data)) 
                        {
                            this.chartData.labels = data.map(item => item.TypeFrozenCategoryName || 'Desconocido');
                            this.chartData.datasets[0].data = data.map(item => item.SumLlpWeightTN || 0);
                        }                        
                    }
                })
            },

            getWeightStateProccess()
            {
                _sPalletSend.getWeightStateProccess({}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        let data = r.data;
                        
                        if (Array.isArray(data)) 
                        {
                            this.chartData2.labels = data.map(item => item.StateProccess || 'Desconocido');
                            this.chartData2.datasets[0].data = data.map(item => item.SumLlpWeightTN || 0);
                        }                        
                    }
                })
            },

            getWeightSiseFruit()
            {
                _sPalletSend.getWeightSiseFruit({}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        let data = r.data;
                        
                        if (Array.isArray(data)) 
                        {
                            this.chartData3.labels = data.map(item => item.TypeFrozenCategoryName || 'Desconocido');
                            this.chartData3.datasets[0].data = data.map(item => item.SumLlpWeightTN || 0);
                        }                        
                    }
                })
            },
            getWeightVariaty()
            {
                _sPalletSend.getWeightVariaty({}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        let data = r.data;
                        
                        if (Array.isArray(data)) 
                        {
                            this.chartData4.labels = data.map(item => item.TypeFrozenCategoryName || 'Desconocido');
                            this.chartData4.datasets[0].data = data.map(item => item.SumLlpWeightTN || 0);
                        }                        
                    }
                })
            }
        }
        ,mounted(){
            this.getTypeSelectionFrozen();
            this.getWeightStateProccess();
            this.getWeightSiseFruit();
            this.getWeightVariaty();
        },created(){
           
        }
    }
</script>
<style>
body{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header{
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    text-align: center;
}

.main-content{
    display: flex;
    flex: 1;
}

.derecha{
    background-color: #f4f4f4;
    padding: 10px;
    width: 200px;
}

.izquierda{
    background-color: #f4f4f4;
    padding: 10px;
    width: 250px;
   
}

.center{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    background-color: #e0e0e0;
    gap: 1rem;   
    padding: 10px;
}
.chart-container {
    width: calc(33.333% - 1rem);
    height: 200px;
    box-sizing: border-box;
}
@media(max-width: 800px){
    .chart-container{
        width: calc(50% - 1rem);
    }
}
@media (max-width: 500px) {
    .chart-container {
        width: 100%; /* Un elemento por fila en pantallas aún más pequeñas */
    }
}
</style>